<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 265px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">お知らせ<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <a @click="navigate('/admin/news')" style="cursor: pointer"
                >ダッシュボード</a
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Control message',
                  params: { type: message_type },
                }"
                >{{ this.messagetype.title_jp }}一覧</router-link
              >
            </li>
            <li><span>新規投稿</span></li>
          </ul>
        </nav>
      </div>
      <div class="action_wrap m_b5" v-if="commonValidationError">
        <p class="s_err">
          入力内容に不備があります。<span
            ><i class="fas fa-times msg_remove"></i
          ></span>
        </p>
      </div>

      <div
        class="modal"
        id="discardModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal_inner confirm">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form_outwrap">
                  <div class="modal_header">
                    <p class="ttl_header">確認</p>
                  </div>
                  <div class="modal_wrap">
                    <div class="for_wrap h_80">
                      <div class="modal_contents">
                        <p>
                          <span
                            >入力途中の内容は削除されますがよろしいですか？</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="modal_footer">
                    <div class="flex_wrap f_c">
                      <div class="btn_norm reverse" id="cancel_bk">
                        <button
                          name=""
                          value="キャンセル"
                          class="close"
                          dusk="delete_data"
                          @click="closeDiscard()"
                        >
                          キャンセル
                        </button>
                      </div>
                      <div class="btn_norm delete">
                        <button
                          @click="discardModal()"
                          class="btn btn-primary cln_form_btn"
                          value="アーカイブ"
                          title="削除"
                          dusk="save"
                        >
                          実行
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p
            id="close_btn"
            class="pop-remove_btn ttoltip tip-top close"
            @click="closeDiscard()"
          >
            <i class="fas fa-times"></i>
          </p>
          <!-- <div class="close_btn tooltip tip-left" title="閉じる">
				<i class="fas fa-times"></i>
			</div> -->
        </div>
        <!--modal_inner-->
      </div>
      <!--modal-->
      <!-- <Spinner v-if="spinner" /> -->
      <!-- Vee-validate form field -->
      <Form
        enctype="multipart/form-data"
        autocomplete="off"
        @submit="update(editmessage.id)"
        class="createform"
      >
        <div class="command_wrap command_bottom">
          <div class="leftarea btn_wrap">
            <div class="return_link">
              <a id="backToList" dusk="to_list_screen">
                <span class="icon"><i class="fas fa-chevron-left"></i></span>
                {{ this.messagetype.title_jp }}一覧
              </a>
            </div>
            <div></div>
          </div>
          <!--leftarea-->
          <div class="rightarea">
            <div>
              <div class="btn_norm">
                <button type="submit" dusk="com_save_button">保存</button>
              </div>
            </div>
            <div class="commandtext_wrap">
              <div class="commandtextlist"></div>
            </div>
            <!--commandtext_wrap-->
          </div>
          <!--rightarea-->
        </div>
        <!--command_wrap-->

        <div class="contentout_wrap">
          <div class="content_wrap twocolumn">
            <div class="box_wrap left">
              <h2 class="ttl_2">新規{{ this.messagetype.title_jp }}</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr v-if="messagecolumn['title']">
                        <th class="wid_200px">{{ messagecolumn["title"] }}</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <Field
                                  name="title"
                                  type="text"
                                  class="form-control"
                                  placeholder="タイトルを入力してください"
                                  v-model="editmessage.title"
                                  id="title"
                                  :rules="isRequired"
                                />
                                <ErrorMessage class="each_msg" name="title" />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="messagecolumn['content']">
                        <th class="wid_200px">
                          {{ messagecolumn["content"] }}
                        </th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0">
                              <div class="in_wrap">
                                <textarea
                                  class="form-control"
                                  name="body"
                                  style="visibility: hidden; display: none"
                                >
                                </textarea>
                                <ckeditor
                                  @input="onEditorInput"
                                  :editor="editor"
                                  v-model="editmessage.content"
                                  :config="editorConfig"
                                ></ckeditor>
                                <Field
                                  name="content"
                                  type="hidden"
                                  class="form-control"
                                  placeholder="本文を入力してください"
                                  v-model="editmessage.content"
                                  id="content"
                                  :rules="isRequired"
                                />
                                <ErrorMessage class="each_msg" name="content" />
                              </div>
                              <!--in_wrap-->
                            </div>
                            <!--wrap-->
                          </div>
                          <!--form_outwrap-->
                        </td>
                      </tr>
                      <tr v-if="messagecolumn['tags']">
                        <th class="wid_200px">{{ messagecolumn["tags"] }}</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="select_wrap multiple">
                                <select
                                  v-model="editmessage.tags"
                                  multiple
                                  class=""
                                >
                                  <option
                                    v-for="taglist in this.taglists.slice()"
                                    v-bind:value="taglist.id"
                                    :key="taglist.id"
                                  >
                                    {{ taglist.name }}
                                  </option>
                                </select>
                                <ErrorMessage class="each_msg" name="group" />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="messagecolumn['target_ids']">
                        <th class="wid_200px">
                          {{ messagecolumn["target_ids"] }}
                        </th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="select_wrap multiple">
                                <div class="searchInput">
                                  <input
                                    type="text"
                                    @keyup="searchGroupUser($event)"
                                    placeholder="ユーザーを絞り込み"
                                  />
                                </div>

                                <select
                                  @change="setAthesekiUser($event)"
                                  v-model="editmessage.target_ids"
                                  multiple
                                  class=""
                                >
                                  <option
                                    v-for="user in this.groupUsers.slice()"
                                    v-bind:value="user.id"
                                    :key="user.id"
                                  >
                                    {{ user.last_name }} {{ user.first_name }}
                                    {{
                                      user.role ? " (" + user.role + ")" : ""
                                    }}
                                  </option>
                                </select>
                                <ErrorMessage class="each_msg" name="group" />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr
                        id="attached_area"
                        v-if="messagecolumn['attached_file']"
                      >
                        <th class="wid_200px">
                          {{ messagecolumn["attached_file"] }}
                        </th>
                        <td>
                          <div class="row_form_wrap">
                            <div class="row_wrap header nosort">
                              <div class="col">
                                <p class="ttl_3">資料タイトル</p>
                              </div>
                              <div class="col">
                                <p class="ttl_3">
                                  アップロードファイル（PDF形式）
                                </p>
                              </div>
                              <div class="icon col wid_50px"></div>
                            </div>
                          </div>
                          <div class="row_form_wrap m_b20" id="itembox_doc">
                            <div
                              v-for="file in editmessage.attached_file"
                              :key="file.id"
                              class="row_wrap sort_item input-file attached_wrap"
                            >
                              <div class="col">
                                <div class="form_outwrap">
                                  <div
                                    class="wrap wid_100per celltxtarea m_b0 p_r0"
                                  >
                                    <div class="in_wrap">
                                      <input
                                        name="file_meta_value[]"
                                        type="text"
                                        :value="file.display_name"
                                        class="f_500 updatedFile fileName"
                                        :data-id="file.id"
                                        placeholder="新規開校○○スクールのご案内"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="col item_list">
                                <div class="form_outwrap">
                                  <div
                                    class="wrap wid_100per celltxtarea m_b0 p_r0"
                                  >
                                    <div class="in_wrap">
                                      <a
                                        :href="file.url"
                                        target="_blank"
                                        class="links"
                                        >{{ file.updaload_name }}</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="icon col wid_50px t_c">
                                <div class="remove_wrap">
                                  <span
                                    @click="delete_file(file.id)"
                                    class="item_doc_remove_btn item_remove_btn tooltip tip-left"
                                    title="削除"
                                    dusk="remove_shiryo"
                                    ><i class="fas fa-times"></i
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <!--row_wrap-->
                          </div>
                          <div
                            class="row_form_wrap m_b20"
                            id="itembox_doc"
                          ></div>
                          <!--row_form_wrap-->
                          <p class="commandtext t_c">
                            <span class="item_doc_add_btn" dusk="zairyo_add"
                              ><i class="material-icons"></i>関連資料追加</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <!--box_wrap-->
            <div class="box_wrap right">
              <h2 class="ttl_2">公開情報</h2>
              <div class="form_outwrap">
                <div
                  v-if="messagecolumn['status']"
                  class="wrap wid_100per p_r0"
                >
                  <div>
                    <p class="ttl_3">{{ messagecolumn["status"] }}</p>
                  </div>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        required=""
                        name="status"
                        as="select"
                        class="form-control"
                        v-model="editmessage.status"
                        :rules="isRequired"
                      >
                        <option value="下書き">下書き</option>
                        <option value="公開">公開</option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="status" />
                  </div>
                </div>
                <!--wrap-->
                <div class="wrap wid_100per p_r0" style="display: none">
                  <div><p class="ttl_3">カテゴリ</p></div>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        name="type"
                        as="select"
                        class="form-control"
                        v-model="editmessage.type"
                      >
                        <option value="news">お知らせ</option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="type" />
                  </div>
                </div>
                <!--wrap-->
                <div v-if="messagecolumn['published_at']" class="wrap p_r0">
                  <p class="ttl_3">{{ messagecolumn["published_at"] }}</p>
                  <div class="in_wrap">
                    <Field
                      name="published_at"
                      type="datetime-local"
                      class="form-control"
                      v-model="editmessage.published_at"
                    />
                    <ErrorMessage class="each_msg" name="published_at" />
                    <!--datepickbox-->
                  </div>
                  <!--in_wrap-->
                </div>
                <!--wrap-->
              </div>
              <!--form_outwrap-->
            </div>
            <!--box_wrap-->
          </div>
          <!--content_wrap-->
        </div>
        <!--contentoutwrap-->
      </Form>
    </div>
    <!--maincontentinner-->
    <template id="item_doc_Template">
      <div class="row_wrap new_item sort_item attached_wrap">
        <div class="col">
          <div class="form_outwrap">
            <div class="wrap wid_100per celltxtarea m_b0 p_r0">
              <div class="in_wrap">
                <input
                  type="hidden"
                  name="jdg_val[]"
                  class="fileHiddenName"
                  value="new"
                />
                <input
                  name="rel_doc_title[]"
                  type="text"
                  class="fileName uploadFileName"
                  required
                  placeholder="例：1月の営業スケジュールについて"
                  value=""
                />
              </div>
              <div class="fileDisplayName"></div>
            </div>
          </div>
          <!--form_outwrap-->
        </div>
        <div class="col item_list">
          <div class="form_outwrap">
            <div class="wrap wid_100per celltxtarea m_b0 p_r0">
              <div class="in_wrap">
                <div class="filegroup">
                  <input
                    type="file"
                    name="rel_doc[]"
                    class="input-file"
                    style="display: none"
                    accept=".pdf"
                    required
                  />
                  <label class="filelabel fileup-btn">ファイルを選択</label>
                </div>
              </div>
            </div>
          </div>
          <!--form_outwrap-->
        </div>
        <div class="icon col wid_50px t_c">
          <div class="remove_wrap">
            <a
              href=""
              class="item_remove_btn remove_btn tooltip tip-left delete_task_link"
              title="削除"
              ><i class="fas fa-times"></i
            ></a>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import "@/assets/front_component/js/select2/select2.min.js";
import $ from "jquery";
import message from "@/apis/Message";
import user from "@/apis/User";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import ClassicEditor from "ckeditor5-build-classic-with-base64-and-autolink/build/ckeditor";
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from "moment";
import Groups from "../../../apis/Groups";

export default {
  name: "message",
  data() {
    return {
      userlists: "",
      taglists: "",
      messages: [],
      editmessage: {
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        target_type: "users",
        type: this.$route.params.type,
        content_type: "HTML",
        thanks_type: [],
      },
      processing: false,
      MessageColumn() {
        return MessageColumn;
      },
      messagecolumn: {},
      MessageType() {
        return MessageType;
      },
      messagetype: {},
      editor: ClassicEditor,
      editorConfig: {},
      editorValidation: true,
      old_fileid: "",
      before_thanks_type: [],
      attached_file: [],
      delete_attached_file: [],
      commonValidationError: false,
      searchGroupId: this.$User.group.id,
      groupUsers: [],
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.message_type = this.$route.params.type;
    this.messagecolumn = MessageColumn[this.message_type];
    this.messagetype = MessageType[this.message_type];
    this.message_id = this.$route.params.id;
    if (!this.messagecolumn["admin_edit"]) {
      this.$router.push({ name: "Front 404" });
    }

    Groups.getAllGroupUsers().then((response) => {
      this.groupUsers = response.data.data;
      this.userlists = response.data.data;
    });

    this.editmessageView(this.message_id);
    this.getUserLists();
    if (this.messagecolumn["tag_list"]) {
      this.getTagLists(this.messagecolumn["tag_list"]);
    }
  },
  mounted() {
    let vueThis = this;
    document.body.className = "page_control";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
    });
    console.log(this.$User);
    $(function () {
      $(".target_user_select").select2({
        width: "260px",
        language: {
          noResults: function () {
            return "登録されていません";
          },
        },
        placeholder: "宛先のユーザーを選択",
        allowClear: true,
        multiple: true,
      });
    });
    $(document).on("change", ".input-file", function (e) {
      var $el = $(e.currentTarget);
      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");
      var $filesSelectName = $el
        .closest(".attached_wrap,.new_item")
        .find(".file_name");
      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");

      $($filesSelectName).text(e.target.files[0].name);

      if ($fileName.val() == "") {
        if (e.target.files[0].name) {
          $fileName.val(e.target.files[0].name);
        } else {
          var today = new Date();
          $fileName.val(today + e.target.files[0].type);
          // $($fileDisplayName).html("資料名を入力してください");
          // $($fileDisplayName).addClass('each_msg')
        }
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });

    $(document).on("change", ".fileName", function (e) {
      var $el = $(e.currentTarget);
      var $fileData = $el
        .closest(".attached_wrap,.new_item")
        .find(".input-file");
      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");
      console.log($fileDisplayName);
      if ($el.val() == "") {
        $($fileDisplayName).html("資料名を入力してください");
        $($fileDisplayName).addClass("each_msg");
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });
    $(document).on("click", ".add_task_link", function (e) {
      var $el = $(e.currentTarget);
      var $itembox = $el.closest(".attached_wrap,.new_item");
      var htmlString = $("#item_doc_Template").html();
      $itembox.after(htmlString);
    });
    $(document).on("click", ".delete_task_link", function (e) {
      var $el = $(e.currentTarget);
      var $row = $el.closest(".attached_wrap,.new_item");
      $row.remove();

      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");
      console.log(vueThis.attached_file);

      for (var i = 0; i < vueThis.attached_file.length; i++) {
        console.log(vueThis.attached_file[i]);
        if (vueThis.attached_file[i].display_name == $fileName.val()) {
          vueThis.attached_file.splice(i, 1);
          break;
        }
      }
      return false;
    });
    $(document).on("blur", ".fileName", function (e) {
      console.log(e);
      var $el = $(e.currentTarget);
      console.log($el);
      $el[0].dataset.value = $el.val();
    });
  },
  methods: {
    onEditorInput(data, event, editor) {
      editor.editing.view.document.on(
        "enter",
        (evt, data) => {
          if (data.isSoft) {
            editor.execute("enter");
          } else {
            editor.execute("shiftEnter");
          }

          data.preventDefault();
          evt.stop();
          editor.editing.view.scrollToTheSelection();
        },
        { priority: "high" }
      );
    },
    delete_file(file_id) {
      this.delete_attached_file.push(file_id);
      console.log(this.delete_attached_file);
    },
    isRequired(value, name) {
      if (value) {
        return true;
      }
      var field_name = this.messagecolumn[name.field];
      return field_name + "は必須です";
    },
    isRequiredArr(value, name) {
      if (value.length != 0) {
        return true;
      }
      var field_name = this.messagecolumn[name.field];
      return field_name + "は必須です";
    },
    editmessageView(id) {
      this.loader = true;
      message
        .edit(id)
        .then((response) => {
          if (response != null) {
            this.editmessage = response.data.data;
            this.message_type = this.$route.params.type;
            console.log("edit");
            console.log(this.editmessage);
            this.getMessageMeta(id);
            this.getMessageEyecatch(id);
            this.getMessageAttachedFile(id);
            this.editmessage.published_at = this.format_date(
              this.editmessage.published_at
            );
            if (this.messagecolumn["published_at"]) {
              this.editmessage.published_at =
                this.editmessage.published_at.replace(" ", "T");
            } else {
              this.editmessage.published_at += ":00";
            }
            this.editmessage.tags = this.editmessage.tags.map(
              (item) => item["id"]
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    update(id) {
      this.processing = true;
      setTimeout(this.enable, 3000);

      this.loader = true;
      if (this.messagecolumn["published_at"] && this.editmessage.published_at) {
        this.editmessage.published_at = this.editmessage.published_at.replace(
          "T",
          " "
        );
        this.editmessage.published_at += ":00";
      }
      if (this.messagecolumn["attached_file"]) {
        for (var i = 0; i < $("#attached_area .attached_wrap").length; i++) {
          let $el = $(".attached_wrap:eq(" + i + ")");
          let $fileName = $el.find(".fileName");

          let $fileData = $el.find(".input-file");
          if ($fileData[0] != undefined) {
            console.log($("#attached_area .attached_wrap").length);
            console.log($fileData[0]);
            if (!($fileName.val() == "" && $fileData[0].files.length == 0)) {
              if ($fileName.val() == "") {
                alert("資料名を入力してください");
                this.attached_file = [];
                return;
              }
              if ($fileData[0].files.length == 0) {
                alert("ファイルを選択してください");
                this.attached_file = [];
                return;
              }
              if (file && permit_type.indexOf(file.type) == -1) {
                alert("アップロードできるファイルの形式はpdfです");
                this.attached_file = [];
                return;
              }

              let fileObject = new FormData();

              fileObject.append("key", "attached_file");
              fileObject.append("display_name", $fileName.val());
              fileObject.append("uploadfile", $fileData[0].files[0]);

              this.attached_file.push(fileObject);

              var file = $fileData[0].files[0];
              var permit_type = ["application/pdf"];
            }
          } else {
            console.log($fileName);
            let $fileId = $el.find(".fileName").attr("data-id");
            let $fileName_change = $el.find(".fileName").attr("data-value");
            let put_file = "";
            if ($fileName_change != null) {
              put_file = {
                key: "attached_file",
                display_name: $fileName_change,
              };
            } else {
              put_file = {
                key: "attached_file",
                display_name: $fileName.val(),
              };
            }
            console.log(put_file);
            this.putMessageAttachedfile(put_file, id, $fileId);
          }
        }
      }
      message
        .update(this.editmessage, id)
        .then(
          (response) => {
            this.putMessageMeta(id);
            if (this.attached_file.length > 0) {
              this.postMessageAttachedfile(response.data.data.id);
            }
            if (this.delete_attached_file.length > 0) {
              this.delete_attached_file.forEach((file_id) => {
                this.deleteAttachedfile(id, file_id);
              });
            }
            this.$router.push({
              name: "Admin messageshow",
              params: { type: this.message_type, id: this.message_id },
            });
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.loader = false;
          }
        )
        .catch(() => {})
        .finally(() => {});
    },
    getUserLists() {
      user
        .list()
        .then((response) => {
          if (response != null) {
            this.userlists = response.data.data;
            console.log(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getTagLists(tag_array) {
      message
        .tag_list(tag_array)
        .then((response) => {
          if (response != null) {
            this.taglists = response.data.data;
            console.log(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD hh:mm");
      }
    },
    getMessageMeta(message_id) {
      if (this.messagecolumn["sdgs_type"]) {
        message
          .showmetakey(message_id, "key", "sdgs_type")
          .then((response) => {
            if (response != null) {
              this.editmessage.sdgs_type = response.data.data[0].value;
              this.editmessage.sdgs_id = response.data.data[0].id;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
      if (this.messagecolumn["group"]) {
        message
          .showmetakey(message_id, "key", "group")
          .then((response) => {
            if (response != null) {
              this.editmessage.group = response.data.data[0].value;
              this.editmessage.group_id = response.data.data[0].id;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
      if (this.messagecolumn["public"]) {
        message
          .showmetakey(message_id, "key", "public")
          .then((response) => {
            if (response != null) {
              this.editmessage.public = response.data.data[0].value;
              this.editmessage.public_id = response.data.data[0].id;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
      if (this.messagecolumn["thanks_type"]) {
        this.editmessage.thanks_type = [];
        message
          .showmetakey(message_id, "key", "thanks_type")
          .then((response) => {
            if (response != null) {
              response.data.data.forEach((thanks_type) => {
                this.editmessage.thanks_type.push(thanks_type.value);
              });
              this.before_thanks_type = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
      console.log("editmessage");
      console.log(this.editmessage);
    },
    putMessageMeta(message_id) {
      if (this.messagecolumn["sdgs_type"]) {
        var post_json = {
          key: "sdgs_type",
          value: this.editmessage.sdgs_type,
          description: "SDGsタイプ",
        };
        message
          .update_meta(post_json, message_id, this.editmessage.sdgs_id)
          .then(
            (response) => {
              console.log("meta");
              console.log(response);
              // this.$router.push({ name: 'Control message', params: { type: (this.message_type) }});
            },
            (error) => {
              console.log("metaerror");
              console.log(error);
              console.log(error.response.data.errors);
              // this.$router.push({ name: 'Front Error' });
            }
          )
          .catch((error) => {
            console.log("metaerrorcatch");
            console.log(error);
            // this.$router.push({ name: 'Front Error Catch' });
          })
          .finally(() => {});
      }
      if (this.messagecolumn["group"]) {
        var post_json_group = {
          key: "group",
          value: this.editmessage.group,
          description: "部門",
        };
        message
          .update_meta(post_json_group, message_id, this.editmessage.group_id)
          .then(
            (response) => {
              console.log(response);
            },
            (error) => {
              console.log(error);
              this.$router.push({ name: "Front Error" });
            }
          )
          .catch((error) => {
            console.log(error);
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {});
      }
      if (this.messagecolumn["public"]) {
        var post_json_public = {
          key: "public",
          value: this.editmessage.public,
          description: "一般公開設定",
        };
        message
          .update_meta(post_json_public, message_id, this.editmessage.public_id)
          .then(
            (response) => {
              console.log(response);
            },
            (error) => {
              console.log(error);
              this.$router.push({ name: "Front Error" });
            }
          )
          .catch((error) => {
            console.log(error);
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {});
      }
      console.log("this.before_thanks_type");
      console.log(this.before_thanks_type);
      if (this.messagecolumn["thanks_type"]) {
        if (this.before_thanks_type) {
          console.log("this.before_thanks_type");
          this.before_thanks_type.forEach((before_type) => {
            console.log(before_type);
            message
              .delete_meta(message_id, before_type.id)
              .then(
                (response) => {
                  console.log(response);
                },
                (error) => {
                  console.log(error);
                  this.$router.push({ name: "Front Error" });
                }
              )
              .catch((error) => {
                console.log(error);
                this.$router.push({ name: "Front Error Catch" });
              })
              .finally(() => {});
          });
        }
        if (this.messagecolumn["thanks_type"]) {
          console.log("this.thanks_type");
          this.editmessage.thanks_type.forEach((thanks_type) => {
            let post_json_thanks_type = {
              key: "thanks_type",
              value: thanks_type,
              description: "サンクスタイプ",
            };
            message
              .register_meta(post_json_thanks_type, message_id)
              .then(
                (response) => {
                  console.log(response);
                },
                (error) => {
                  console.log(error);
                  this.$router.push({ name: "Front Error" });
                }
              )
              .catch((error) => {
                console.log(error);
                this.$router.push({ name: "Front Error Catch" });
              })
              .finally(() => {});
          });
        }
      }
    },
    getMessageEyecatch(message_id) {
      if (this.messagecolumn["photolog"]) {
        message
          .showfile(message_id, "key", "photolog")
          .then((response) => {
            if (response != null) {
              this.editmessage.photolog = response.data.data[0];
              this.old_fileid = response.data.data[0].id;
              console.log(this.old_fileid);
              let fileApiPass =
                "/api/v1/file/" + this.editmessage.photolog.storage_file_name;
              console.log(fileApiPass);
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then((response) => {
                  let blob = new Blob([response.data], {
                    type: response.headers["content-type"],
                  });
                  let img = document.getElementById("img_tag_id");
                  let url = window.URL || window.webkitURL;
                  img.src = url.createObjectURL(blob);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },
    putMessagefile(key, display_name, id) {
      if (this.messagecolumn["photolog"]) {
        if (this.editmessage.photolog[0]) {
          message
            .file_delete(id, this.old_fileid)
            .then(
              (response) => {
                console.log("file");
                console.log(response);
              },
              (error) => {
                console.log("fileerror");
                console.log(error);
                console.log(error.response.data.errors);
                this.$router.push({ name: "Front Error" });
              }
            )
            .catch((error) => {
              console.log("fileerrorcatch");
              console.log(error);
              this.$router.push({ name: "Front Error Catch" });
            })
            .finally(() => {});

          let fileObject = new FormData();
          fileObject.append("key", key);
          fileObject.append("display_name", display_name);
          fileObject.append("uploadfile", this.editmessage.photolog[0]);

          message
            .register_file(fileObject, id)
            .then(
              (response) => {
                console.log("file");
                console.log(response);
              },
              (error) => {
                console.log("fileerror");
                console.log(error);
                console.log(error.response.data.errors);
                this.$router.push({ name: "Front Error" });
              }
            )
            .catch((error) => {
              console.log("fileerrorcatch");
              console.log(error);
              this.$router.push({ name: "Front Error Catch" });
            })
            .finally(() => {});
        }
      }
    },
    getMessageAttachedFile(message_id) {
      if (this.messagecolumn["attached_file"]) {
        message
          .showfile(message_id, "key", "attached_file")
          .then((response) => {
            if (response.data.data.length != 0) {
              this.editmessage.attached_file = response.data.data;
              console.log(this.editmessage.attached_file);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },
    dlfile(path) {
      //let fileApiPass = '/api/v1/file/adedd8819fecb6f2abd2756a7771803d?attachment';
      let fileApiPass = "/api/v1/file/" + path;
      console.log(fileApiPass);
      Api()
        .get(fileApiPass, { responseType: "arraybuffer" })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          /**
           * 新規タブ
           */
          window.open(window.URL.createObjectURL(blob));
        });
    },
    enable: function () {
      this.processing = false;
    },
    async postMessageAttachedfile(createdId) {
      for (var i = 0; i < this.attached_file.length; i++) {
        await message
          .register_file(this.attached_file[i], createdId)
          .then(
            (response) => {
              if (i == this.attached_file.length - 1) {
                if (response != "") {
                  console.log(response);
                }
              }
            },
            (error) => {
              console.log(error);
            }
          )
          .catch(() => {})
          .finally(() => {});
      }
    },
    putMessageAttachedfile(put_file, id, $fileId) {
      message
        .update_file(put_file, id, $fileId)
        .then(
          (response) => {
            console.log("meta");
            console.log(response);
            // this.$router.push({ name: 'Control message', params: { type: (this.message_type) }});
          },
          (error) => {
            console.log("metaerror");
            console.log(error);
            console.log(error.response.data.errors);
            // this.$router.push({ name: 'Front Error' });
          }
        )
        .catch((error) => {
          console.log("metaerrorcatch");
          console.log(error);
          // this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {});
    },
    deleteAttachedfile(id, file_id) {
      message
        .file_delete(id, file_id)
        .then(
          (response) => {
            console.log("file");
            console.log(response);
          },
          (error) => {
            console.log("fileerror");
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log("fileerrorcatch");
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    searchGroupUser(event) {
      Groups.getSearchGroupUsers(
        null,
        event.target.value,
        this.message_type
      ).then((response) => {
        this.groupUsers = response.data.data;
        this.userlists = response.data.data;
      });
    },
    setAthesekiUser(e) {
      if (!this.editmessage.target_ids.includes(e.target.value)) {
        this.editmessage.target_ids.push(e.target.value);
      }
    },
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
@import "~@/assets/front_component/js/timepicker/datepicker.css";
</style>
